import {graphql, Link} from 'gatsby'
import * as React from "react"
import "../styles/styles.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Container, Row, Col} from "react-bootstrap";
import { LayoutBootstrap } from "../components/layoutBootstrap";
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LanguageContext } from '../components/language';
import dayjs from 'dayjs';
import { T } from '../translation/translate';
import { useContext } from 'react';


const IndexPage = ({data}) => {
  const {lang} = useContext(LanguageContext);
  const now = dayjs();
  const laterEvents = data.allCalendarEvent.edges.filter(e=> dayjs(e.node.start.dateTime) >= now);


  return (
    <>
      <LayoutBootstrap>
        {/* Headimage plus title */}
        <Container fluid style={{backgroundColor:"#6ba7cf"}}>
          <Row className="no-gutters">
            <Col style={{padding:0, display: 'grid'}}>
              <StaticImage
                src={"../images/pages/homepage.png"} 
                loading="eager"
                className="image" 
                style={{gridArea:'1/1'}}
                imgStyle={{quality:100}}
                alt = "homepagepicture"
              />
              <div
                style={{
                  gridArea:'1/1', 
                  position:'relative',
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
                >
              {/* medium screens */}
              <p className="d-none d-sm-block"
               style={{
                    // zIndex:50, 
                    fontSize:'5rem',
                    color: 'white'}}
              >
                { T(lang, {nl: 'Welkom bij InterSECtion', en: 'Welcome to InterSECtion',})}
              </p>
              {/* small screens */}
              <p  className="d-block d-sm-none" 
              style={{
                    // zIndex:50, 
                    fontSize:'2rem',
                    color: 'white'}}
              >
               { T(lang, {nl: 'Welkom bij InterSECtion', en: 'Welcome to InterSECtion',})}
              </p>
              </div>
            </Col>
          </Row>
        </Container>


        <Container fluid>
          <Row>
            {/* News part */}
            <Col  className="oddcontainer">
              <Link to="/news" className="boldlink"><h1 className="bodytext">{ T(lang, {nl: 'Nieuws', en: 'News',})}</h1></Link>
            
              {data.allMdx.edges.filter(e => e.node.frontmatter.language === lang).slice(0,3).map(element =>
                <Row className="newsitem">
                    <Col  md={4} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                      <GatsbyImage image={getImage(element.node.frontmatter.featuredImage)} alt={"picture"}/>   
                    </Col>
                    <Col md={8}>
                      <Link to={"/news/"+ element.node.slug} className="bluelink">
                      <h4>{element.node.frontmatter.title}</h4>
                      </Link>
                      <p>{element.node.frontmatter.date}</p>
                      <p>{element.node.excerpt}</p>
                    </Col>
                </Row>)}
                <Link to="/news" className="boldlink">{ T(lang, {nl: 'Lees meer...', en: 'Read more...',})}</Link>
            </Col>

            {/* Calendar part */}
            <Col className="oddcontainer">
              <a className="boldlink" href="https://calendar.google.com/calendar/embed?src=u3ichbkpp84g1oq5a4iaauune8%40group.calendar.google.com&ctz=Europe%2FAmsterdam">
                <h1 className="bodytext">{ T(lang, {nl: 'Agenda', en: 'Calendar',})}</h1>
              </a>
              

              {laterEvents.map( i =>
                <Row className="agendaitem" >
                  <Col md={4} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <h1>{dayjs(i.node.start.dateTime).format('D MMMM')}</h1>
                  </Col>
                  <Col md={8}>
                    <h4>{i.node.summary}</h4>
                    <p>{dayjs(i.node.start.dateTime).format('h:mm A')}</p>
                    <p>{i.node.description}</p>
                  </Col>
                </Row>
              )}
              <a className="boldlink" href="https://calendar.google.com/calendar/embed?src=u3ichbkpp84g1oq5a4iaauune8%40group.calendar.google.com&ctz=Europe%2FAmsterdam" >{T(lang,{en: "More events...", nl:"Meer evenementen..."})}</a>
            </Col>

            {/* Intersection part */}
            <Container fluid className="evencontainer" >
                  <Row xs={2} md={4}>
                    <Col>
                      <Link style={{textDecoration: 'none'}} to="/association">
                        <h1 
                          className="bodytext" 
                          style={{
                            display:'flex', 
                            flexDirection:'column', 
                            justifyContent:'flex-end'
                          }}
                        >
                          Intersection
                        </h1>
                      </Link>
                      <span  className="bodytext">{ T(lang, {nl: 'Sinds 2008', en: 'Since 2008',})}</span>
                    </Col>
                    
                  </Row>
                  <Row className="justify-content-md-center" >
                    <Col md="3" >
                      <StaticImage
                        src={"../images/pages/community.png"} 
                        className="intersection" 
                        imgStyle={{height:'5rem', width:'auto', margin:'auto'}} 
                        alt = "community"/>
                      <span
                        className="bodytext intersection" 
                        style={{fontSize:'1.2rem', margin:'auto'}}
                      >
                        { T(lang, {nl: 'Gemeenschap', en: 'Community',})}
                      </span>
                    </Col>
                    <Col md="3">
                      <StaticImage
                      src={"../images/pages/perspective.png"} 
                      className="intersection" 
                      imgStyle={{height:'5rem', width:'auto', margin:'auto'}} 
                      alt = "perspective"/>
                      <span 
                        className="bodytext intersection" 
                        style={{fontSize:'1.2rem', margin:'auto'}}
                      >
                        { T(lang, {nl: 'Perspectief in het vakgebied', en: 'Perspective in the field',})}
                      </span>
                    </Col>
                    <Col Col md="3">
                      <StaticImage
                      src={"../images/pages/education.png"} 
                      className="intersection" 
                      imgStyle={{height:'5rem', width:'auto', margin:'auto'}} 
                      alt="education"/>
                      <span 
                      className="bodytext intersection" 
                      style={{fontSize:'1.2rem', margin:'auto'}} 
                      >
                        { T(lang, {nl: 'Onderwijs', en: 'Education',})}
                      </span>
                    </Col>
                  </Row>
            </Container>
          </Row>
        </Container>

      </LayoutBootstrap>
    </>
  )
}

export default IndexPage

export const query = graphql`
query MyQuery {
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, quality:100)
            }
          }
          language
          date(formatString: "D MMMM YYYY")
          location
        }
        excerpt(pruneLength: 100)
        slug
      }
    }
  }

  allCalendarEvent(sort: {order: ASC, fields: start___dateTime}) {
      edges {
        node {
          summary
          description
          start {
            dateTime
          }
        }
      }
    }
}
`
